import React from 'react';
import { Label } from 'semantic-ui-react'
import { Input, Button, Form, TextArea } from 'semantic-ui-react'
import { Segment } from 'semantic-ui-react'
import moment from 'moment-es6'

export default class CustomerDetails extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    //console.log(this);
    var reason = this.props.callReason ? this.props.callReason : '';
    //const reasonMissing = !(this.props.callReasonDetails ? true : false);
    const validationFailuresExist = this.props.validationFailure;
    const formvalidationErrors = this.props.validationErrors;
    //var selectedReasonCount = reason.length;

    var validationMessage;
    if (formvalidationErrors.callWrapReasonExtra) { validationMessage = "You cannot select more than two reasons." }
    else if (formvalidationErrors.callWrapReasonMissing) { validationMessage = "Please select a reason." }
    const callReasonValidation = ((formvalidationErrors.callWrapReasonExtra || formvalidationErrors.callWrapReasonMissing) ? <Label basic color='red' pointing='below'>{validationMessage}</Label> : '');
    const callreasonDetailsValidation = (formvalidationErrors.callWrapReasonDetailsMissing ? <Label basic color='red' pointing>Please provide reason details.</Label> : '');
    const notesValidation = (formvalidationErrors.CallWrapNotesMissing ? <Label basic color='red' pointing>Please add notes for this call wrap reason.</Label> : '');
    // console.log('Call reason detail' + this.props.callReasonDetails);
    let date2 = null;
    if(this.props.customer.BatchDate){date2  = new Date(this.props.customer.BatchDate);}
    else if(this.props.customer.EnteredDate){date2  = new Date(this.props.customer.EnteredDate);}
    let timeDiff = Math.abs(new Date().getTime() - date2.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    const NoteDMC = (diffDays < 7 ? <Label basic color='green'>DMC may not be available yet.</Label> : '' )
    
    return (


      <Segment> <h3>Customer Details</h3>
        <Segment>
          <Form>

            <Form.Group widths='equal' >
              <Form.Field>
                <label>Status</label>
                {this.props.customer.Inactive === 'true' ? <Label as='a' color='red' tag>Inactive Customer</Label> : <Label as='a' color='teal' tag>Active Customer</Label>}
                {NoteDMC}
              </Form.Field>
              <Form.Input label='Membership Number' name='MembershipNo' value={this.props.customer.MembershipNo} readOnly />
            </Form.Group>


            <Form.Group widths='equal' >

              <Form.Field>
                {!this.props.customer.FirstName ? <Label basic color='red' pointing='below'>Please provide First Name</Label> : ''}
                <label>First Name</label>
                <Input name='FirstName' defaultValue={this.props.customer.FirstName} onChange={this.props.onCustomerDetailsChange} required readOnly={this.props.customer.Inactive === 'true'} />
              </Form.Field>

              {/* <Form.Input label='First Name' name='FirstName' defaultValue={this.props.customer.FirstName} onChange={this.props.onCustomerDetailsChange} required /> */}
              <Form.Input label='Middle Name (* only initial displayed on card)' name='MiddleName' width='10' defaultValue={this.props.customer.MiddleName} readOnly />

              <Form.Field>
                {!this.props.customer.LastName ? <Label basic color='red' pointing='below'>Please provide Last Name</Label> : ''}
                <label>Last Name</label>
                <Input name='LastName' defaultValue={this.props.customer.LastName} onChange={this.props.onCustomerDetailsChange} required readOnly={this.props.customer.Inactive === 'true'} />
              </Form.Field>


              {/* <Form.Input label='Last Name' name='LastName' defaultValue={this.props.customer.LastName} onChange={this.props.onCustomerDetailsChange} required /> */}
            </Form.Group>
            {!this.props.customer.AddressLine1 ? <Label basic color='red' pointing='below'>Please provide Address</Label> : ''}
            <Form.Group widths='equal' >
              <Form.Input label='Address Line1' name='AddressLine1' defaultValue={this.props.customer.AddressLine1} onChange={this.props.onCustomerDetailsChange} required readOnly={this.props.customer.Inactive === 'true'} />
              <Form.Input label='Address Line2' name='AddressLine2' defaultValue={this.props.customer.AddressLine2} onChange={this.props.onCustomerDetailsChange} readOnly={this.props.customer.Inactive === 'true'} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input label='City' name='City' defaultValue={this.props.customer.City} onChange={this.props.onCustomerDetailsChange} readOnly={this.props.customer.Inactive === 'true'} />
              <Form.Input label='State' name='State' defaultValue={this.props.customer.State} onChange={this.props.onCustomerDetailsChange} readOnly={this.props.customer.Inactive === 'true'} />
            </Form.Group>
            {/* {!this.props.customer.ZIP? <Label basic color='red' pointing='below'>Please provide zip code</Label> : '' } */}
            <Form.Group widths='equal'>
              <Form.Input label='ZIP' name='ZIP' defaultValue={this.props.customer.ZIP} onChange={this.props.onCustomerDetailsChange} readOnly={this.props.customer.Inactive === 'true'} />
              <Form.Field>
                {!this.props.customer.CountryCode ? <Label basic color='red' pointing='below'>Please provide Country name</Label> : ''}
                <label>Country</label>
                <Input name='CountryCode' defaultValue={this.props.customer.CountryCode} onChange={this.props.onCustomerDetailsChange} required readOnly={this.props.customer.Inactive === 'true'} />
              </Form.Field>
            </Form.Group>
          </Form>
          <br/>
          <div align="center">
          <Label basic color='blue' pointing='below' size='large'>Is there anything else I can assist you with? Thank you for calling Card Benefit Services.</Label>
          </div>
          <Segment>
            <Form>
              <Form.Field>
                <label>Notes</label>
              </Form.Field>
              <TextArea name='Notes' autoHeight placeholder='Some additional details about this call/customer.' style={{ minHeight: 100 }} maxLength="150" defaultValue={this.props.customer.Notes} onChange={this.props.onCustomerDetailsChange} /> {validationFailuresExist ? notesValidation : ''}
            </Form>
            <br />
            <Form>
              {validationFailuresExist ? callReasonValidation : ''}
              <Form.Field>
                <label>Call Wrap reason</label>
              </Form.Field>
              <Form.Group widths='equal' required>
                {/* <Form.Radio label='Replacement card' value='Replacement card' checked={reason === 'Replacement card'} onChange={this.props.onCallReasonChange} />  */}
                <Form.Checkbox label='General Benefit Inquiry' value='General Benefit Inquiry' checked={reason.includes('General Benefit Inquiry')} onChange={this.props.onCallReasonChange} />
                <Form.Checkbox label='Membership number/DMC' value='Membership number/DMC' checked={reason.includes('Membership number/DMC')} onChange={this.props.onCallReasonChange} />
                <Form.Checkbox label='Membership Status/Renewal' value='Membership Status/Renewal' checked={reason.includes('Membership Status/Renewal')} onChange={this.props.onCallReasonChange} />
                <Form.Checkbox label='Membership Status/New Enrollment' value='Membership Status/New Enrollment' checked={reason.includes('Membership Status/New Enrollment')} onChange={this.props.onCallReasonChange} />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Checkbox label='Enrollment Not Yet Received' value='Enrollment Not Yet Received' checked={reason.includes('Enrollment Not Yet Received')} onChange={this.props.onCallReasonChange} />
                <Form.Checkbox label='Wants to enroll/provided FI number' value='Wants to enroll/provided FI number' checked={reason.includes('Wants to enroll/provided FI number')} onChange={this.props.onCallReasonChange} />
                <Form.Checkbox label='Wants Materials Expedited' value='Wants Materials Expedited' checked={reason.includes('Wants Materials Expedited')} onChange={this.props.onCallReasonChange} />
                <Form.Checkbox label='Tech Support' value='Tech Support' checked={reason.includes('Tech Support')} onChange={this.props.onCallReasonChange} />
              </Form.Group>
              <Form.Group>
                {this.props.customer.Inactive === 'false' ? <Form.Checkbox label='Replacement Card' value='Replacement Card' checked={reason.includes('Replacement Card')} onChange={this.props.onCallReasonChange} width={4} /> : ''}
                <Form.Checkbox label='Other' value='Other' checked={reason.includes('Other')} onChange={this.props.onCallReasonChange} width={4} />

              </Form.Group>
              {reason.includes('Other') ? <Form.Field ><label>Please provide details</label><Input name='Reason Details' width='5' onChange={this.props.onCallReasonDetailsChange} /> {validationFailuresExist ? callreasonDetailsValidation : ''} </Form.Field> : ''}
              <br />
              <Form.Field>
                <label>Complaint Wrap reason</label>
              </Form.Field>
              <Form.Group widths='equal'>
                <Form.Checkbox label='Bank Incorrect Info' value='Bank Incorrect Info' checked={reason.includes('Bank Incorrect Info')} onChange={this.props.onCallReasonChange} />
                <Form.Checkbox label='Call Transfer Issue' value='Call Transfer Issue' checked={reason.includes('Call Transfer Issue')} onChange={this.props.onCallReasonChange} />
                <Form.Checkbox label='Asked for Supervisor' value='Asked for Supervisor' checked={reason.includes('Asked for Supervisor')} onChange={this.props.onCallReasonChange} />
                <Form.Checkbox label='Bank Issue (not Priority Pass)' value='Bank Issue (not Priority Pass)' checked={reason.includes('Bank Issue (not Priority Pass)')} onChange={this.props.onCallReasonChange} />
              </Form.Group>
              <Form.Group>

                <Form.Checkbox label='Entrance/Experience Issues' value='Entrance/Experience Issues' checked={reason.includes('Entrance/Experience Issues')} onChange={this.props.onCallReasonChange} width={4} />
                <Form.Checkbox label='Poor Web Experience' value='Poor Web Experience' checked={reason.includes('Poor Web Experience')} onChange={this.props.onCallReasonChange} width={4} />
                <Form.Checkbox label='Threat of Escalation' value='Threat of Escalation' checked={reason.includes('Threat of Escalation')} onChange={this.props.onCallReasonChange} width={4} />
              </Form.Group>


            </Form>
          </Segment>
          {/* <Segment textAlign='center'> */}
          <div>
            <Button secondary onClick={this.props.onBackToSearchResults}>Go back to Search Results</Button>
            <Button primary onClick={this.props.onCustomerSave}> Wrap this call</Button>
          </div>
          {/* </Segment> */}
        </Segment>
        <Segment>
          <Form>
            {/* <Form.Group widths='equal'> */}
            {/* <Form.Checkbox label='Inactive' name='Inactive' value={this.props.customer.Inactive} readonly /> */}

            {/* </Form.Group> */}
            <Form.Checkbox label='Rush request' name='Rush' defaultValue={this.props.customer.Rush} readOnly />
            <Form.TextArea label='Change Information' name='ChangeInformation' style={{ minHeight: 107 }} value={this.props.customer.ChangeInformation ? this.props.customer.ChangeInformation : ''} readOnly />

            <Form.Group widths='equal'>
              <Form.Input label='Batch Description' name='BatchDescription' value={this.props.customer.BatchDescription} readOnly />
              <Form.Input label='Batch Date' name='BatchDate' value={moment(this.props.customer.BatchDate.substring(0, 10)).format("YYYY-MM-DD")} readOnly />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input label='Return Mail Recieved and Shared' name='ReturnedMailReceivedAndShred' value={this.props.customer.ReturnedMailReceivedAndShred} readOnly />
              <Form.Input label='Record ID' name='RecordID' value={this.props.customer.RecordID} readOnly />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input label='Program Code' name='ProgramCode' value={this.props.customer.ProgramCode} readOnly />
              <Form.Input label='Role' name='Role' value={this.props.customer.Role} readOnly />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input label='CPC / Customer ID' name='CPC' value={this.props.customer.CPC} readOnly />
              <Form.Input label='CCN / ARN' name='CCA' value={this.props.customer.CCA} readOnly />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input label='DMC' name='DMC' value={this.props.customer.DMC} readOnly />
              <Form.Input label='Termination Info' name='TerminationInfo' value={this.props.customer.TerminationInfo} readOnly />

            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input label='Expiration Date' name='ExpDate' value={moment(this.props.customer.ExpDate.substring(0, 10)).format("YYYY-MM-DD")} readOnly />

              <Form.Input label='Enrollment Status' name='EnrollmentStatus' value={this.props.customer.EnrollmentStatus} readOnly />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input label='Account Status' name='AccountStatus' value={this.props.customer.AccountStatus} readOnly />

              <Form.Input label='Entered' name='EnteredDate' value={moment(this.props.customer.EnteredDate.substring(0, 10)).format("YYYY-MM-DD")} readOnly />
            </Form.Group>
            <Form.Group widths='equal'>

              <Form.Input label='Last Modified Date' name='LastModifiedDate' value={moment(this.props.customer.LastModifiedDate.substring(0, 10)).format("YYYY-MM-DD HH:MM")} readOnly />
              <Form.Input label='Last Modified by User' name='LastModifiedBy' value={this.props.customer.LastModifiedBy} readOnly />
            </Form.Group>



          </Form>
        </Segment>
      </Segment>
    );
  }
}

