export function getQueryString() {
    const { search: q } = window.location

    if (!q) return {}

    return (/^[?#]/.test(q) ? q.slice(1) : q)
        .split('&')
        .reduce((params, param) => {
            let [key, value] = param.split('=');
            params[key] = value ? decodeURIComponent(value) : '';
            return params;
        }, {})
}

export function getStage() {
    const host = window.location.hostname

    // console.log(host.split('.')[0]);

    switch (host.split('.')[0]) {
        case 'localhost':
            return 'local'
        case 'cbsiui':
            if (host.split('.')[1] === 'nonprod-asurion53') { return 'qa'; }
            else if (host.split('.')[1] === 'asurion53') { return 'prod'; }
            break;
        case 'prioritypass':
            if(host.split('.')[1] === 'dev') { return 'dev'; }
        default:
            return 'local'
    }
}