
import { UserManager, WebStorageStateStore } from 'oidc-client'
import { getStage } from "./misc";

const stage = getStage();
var config = require('./config/config_' + stage);


export default new UserManager({
  "authority": config.oidc_settings.authority,
  "client_id": config.oidc_settings.client_id,
  "redirect_uri": config.oidc_settings.redirect_uri,
  "response_type": config.oidc_settings.response_type,
  "scope": config.oidc_settings.scope,
  userStore: new WebStorageStateStore({ store: window.sessionStorage })
});

