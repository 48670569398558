import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import registerServiceWorker from './registerServiceWorker'

import SearchCustomer from './components/App'
import AuthorizeUser from './components/AuthorizeUser'
import AuthenticateUser from './components/AuthenticateUser'
import requireAuth from './components/AuthenticationCheck'

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path='/customerLookup' component={requireAuth(SearchCustomer)} />
      {/* <Route path='/customerLookup' component={SearchCustomer} /> */}
      <Route path='/search' component={AuthorizeUser} />
      <Route path='/unauthorized' render={() => <div><h1>Access Denied</h1><h2>You are not authorized to view this page.</h2></div>} />
      <Route path='/' component={AuthenticateUser} />
    </Switch>
  </BrowserRouter>

  , document.getElementById('root'));

registerServiceWorker();