import React from 'react';
import { Table } from 'semantic-ui-react'
import moment from 'moment-es6'

export default class CustomerResultsRow extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <Table.Row selectable onClick={() => this.props.onCustomerClick(this.props.CustomerId)}>
        <Table.Cell > {this.props.ProgramCode}</Table.Cell>
        <Table.Cell >{this.props.FirstName}</Table.Cell>
        <Table.Cell >{this.props.LastName}</Table.Cell>
        <Table.Cell >{this.props.FullAddress}</Table.Cell>
        <Table.Cell >{this.props.MembershipNo}</Table.Cell>
        <Table.Cell >{this.props.Role}</Table.Cell>
        <Table.Cell >{moment(this.props.ExpDate.substring(0, 10)).format("YYYY-MM-DD")}</Table.Cell>
        <Table.Cell  >{this.props.Inactive === 'NO'? 'Active' : 'Inactive'}</Table.Cell>
        <Table.Cell  >{moment(this.props.EnteredDate.substring(0, 10)).format("YYYY-MM-DD")}</Table.Cell>
      </Table.Row>
    );

  }
}
