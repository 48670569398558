import React from 'react';
import { getStage } from "../../misc";
import oidcUserManager from '../../oidcManager';

const stage = getStage()
var config = require('../../config/config_' + stage)

export default class AuthorizeUser extends React.Component {

    constructor(props) {
        super(props);

        oidcUserManager.signinRedirectCallback().then(usr => {

            let validUser = config.adGroups.map(g=> g.toUpperCase()).some(r => usr.profile.groups.map(g=> g.toUpperCase()).includes(r));

            if (!validUser) {
                console.log('Invalid User');
                this.props.history.push('/unauthorized');
            }
            else {
                console.log('Valid User');
                this.props.history.push('/customerLookup');
            }

            //this.props.history.push('/customerLookup');

            // console.log('accesstoken is', usr.access_token);
        }).catch(function (err) {
            console.log('Exception when calling get user ', err);
        });
    }

    render() { return ''; }
}
