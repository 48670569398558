import React from 'react';
import DatePicker from "react-datepicker";
import { Form, Button, Label, Segment, Divider } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import { getStage } from "../../misc";

const stage = getStage()
var config = require('../../config/config_' + stage)

export default class ComplaintReport extends React.Component {
    // constructor(props) {
    //   super(props);
    // }



    render() {

        let userSessionData = JSON.parse(window.sessionStorage.getItem("oidc.user:" + config.oidc_settings.authority + ":" + config.oidc_settings.client_id));
        let isReportUser = config.reportADGroups.map(g=> g.toUpperCase()).some(r => userSessionData.profile.groups.map(g=> g.toUpperCase()).includes(r));

        var validationError =
            this.props.validationErrors.reportDatesRange ? <Label basic color='red'>Date range is Invalid. End date shall be greater that start date and date difference shall not be more than 7 days.</Label> : '';

        if (!isReportUser) {
            this.props.history.push('/unauthorized');
            return '';
        }
        return (
            <Segment.Group compact>
            <Segment>
                {validationError}
                <Form>
                <Divider hidden />
                    <Form.Group widths='inline' >
                        <Form.Field>
                            <label>Start Date</label>
                            <DatePicker
                                selected={this.props.startDate}
                                onChange={this.props.handleStartChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>End Date</label>
                            <DatePicker
                                selected={this.props.endDate}
                                onChange={this.props.handleEndChange}
                            />
                        </Form.Field>
                        {/* <Form.Field> */}
                        <Button onClick={this.props.getweeklyReports}>Download Report</Button>
                        {/* </Form.Field> */}
                    </Form.Group>
                    <Divider hidden />
                </Form>

                {/* <Table celled selectable>
                        <Table.Header>
                            <Table.Row> </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Start Date</Table.HeaderCell>
                                <Table.HeaderCell>End Date</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>ß
                            <Table.Row >
                                <Table.Cell >  <DatePicker
                                    selected={this.props.startDate}
                                    onChange={this.props.handleStartChange}
                                /></Table.Cell>
                                <Table.Cell > <DatePicker
                                    selected={this.props.endDate}
                                    onChange={this.props.handleEndChange}
                                /></Table.Cell>
                                <Table.Cell >  <Button onClick={this.props.getweeklyReports}>Download Report</Button></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table> */}

            </Segment>
            </Segment.Group>
            
        );
    }
}