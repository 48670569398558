import React from 'react';
import { Input, Button, Message, Label, Segment } from 'semantic-ui-react'
import { Dimmer, Loader } from 'semantic-ui-react'

import SearchCustomerResults from '../../components/SearchCustomerResults'
import CustomerDetails from '../../components/CustomerDetails'
import { getStage } from "../../misc";
import ComplaintReport from '../Reports';

var FileSaver = require('file-saver');

const stage = getStage()
var config = require('../../config/config_' + stage)

export default class SearchCustomer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
      searchCount: 0,
      isUserAutheticated: false,
      isAuthorized: false,
      callStartTime: new Date().toISOString(),
      displayReportsControl: false,
      validationErrors: { callWrapReasonMissing: null, callWrapReasonExtra: null, callWrapReasonDetailsMissing: null, CallWrapNotesMissing: null, reportDatesRange: null }
    };

    this.validateCustomerDetails = this.validateCustomerDetails.bind(this);
    this.captureSearchTerm = this.captureSearchTerm.bind(this);
    this.handleCustomerSearch = this.handleCustomerSearch.bind(this);
    this.handleCustomerDetailsChange = this.handleCustomerDetailsChange.bind(this);
    this.handleCustomerSave = this.handleCustomerSave.bind(this);
    this.handleBackToSearchResults = this.handleBackToSearchResults.bind(this);
    this.handleCallResonChange = this.handleCallResonChange.bind(this);
    this.handleCallReasonDetailsChange = this.handleCallReasonDetailsChange.bind(this);
    this.isJsonString = this.isJsonString.bind(this);
    this.openInNewTab = this.openInNewTab.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this)
    this.validateReportDates = this.validateAndGetWeeklyReports.bind(this);
    this.handleDisplayResults = this.handleDisplayResults.bind(this);
  }

  handleCustomerSelection = (id) => {
    //this.setState({ selectedCustomerID: id });
    this.setState({ SearchError: null });
    this.setState({ displaySearchResults: false });
    let selectedCustomer = this.state.searchResults.find(function (result) {
      // console.log(result);
      return result.ID === id
    });

    selectedCustomer.Notes = null;

    this.setState({
      selectedCustomerDetails: //this.state.searchResults.find(function (result) {
        //// console.log(result);
        // return result.ID === id
        //})
        selectedCustomer
    });
    this.setState({
      oldState_selectedCustomerDetails: selectedCustomer
    });
    //alert(this.state.selectedCustomer.ID);
  }

  handleStartDateChange(date) {
    this.setState({
      reportStartDate: date
    });
  }

  handleEndDateChange(date) {
    this.setState({
      reportEndDate: date
    });
  }

  validateAndGetWeeklyReports() {
    let isValid = true;
    if (!this.state.reportStartDate || !this.state.reportEndDate) {
      isValid = false;
    }
    else if (this.state.reportStartDate > this.state.reportEndDate) { isValid = false; }
    // let dayDifference = Math.floor((Date.UTC(this.state.reportEndDate.getFullYear(), this.state.reportEndDate.getMonth(), this.state.reportEndDate.getDate()) - Date.UTC(this.state.reportStartDate.getFullYear(), this.state.reportStartDate.getMonth(), this.state.reportStartDate.getDate())) / (1000 * 60 * 60 * 24));
    else {
      let dayDifference = this.state.reportEndDate.diff(this.state.reportStartDate, 'days');
      if (dayDifference > 7) {
        isValid = false;
      }
    }
    let fieldValidationErrors = { callWrapReasonMissing: null, callWrapReasonExtra: null, callWrapReasonDetailsMissing: null, CallWrapNotesMissing: null, reportDatesRange: null }
    if (isValid) {
      this.setState({ validationErrors: fieldValidationErrors });
      //Make a call to getReports
      this.getComplaintReport();
    }
    else {
      fieldValidationErrors.reportDatesRange = true;
      this.setState({ validationErrors: fieldValidationErrors });
    }
  }

  getComplaintReport() {

    var sessionData = JSON.parse(window.sessionStorage.getItem("oidc.user:" + config.oidc_settings.authority + ":" + config.oidc_settings.client_id));
    var accessToken;

    if (sessionData) {
      //console.log('OIDC User: '+sessionData.access_token)
      accessToken = sessionData.access_token;
    }

    let reportUrl = config.apigateway_settings.base_server + "cbsi/v1/reports/customer/compliant?startDate=" + this.state.reportStartDate.format('YYYYMMDD') + "&endDate=" + this.state.reportEndDate.format('YYYYMMDD')
    console.log(reportUrl);

    this.setState({ inProgress: true });

    fetch(reportUrl, {  //with authentication

      method: 'GET',
      headers: {
        RequestTimeStamp: '2014-07-29T16:31:10.75-04:00',
        UniqueRequestId: '27bd474b-b62d-4ad3-ad9c-79800ce1e7c9',
        SessionId: '6e883d66-5ae5-48da-8b54-8bbfc4157df5',
        Sender: 'PORTAL',
        Authorization: 'Bearer ' + accessToken
      },
    })
      .then(res => {
        if (res.ok) {
          // if (res.status === 204) {
          //   throw Error('[try searching another way] [or ask] When did you complete enrollment?  it can take up to 6 days before we can see your priority pass in our system, I would recommend that you give us a call back after that timeframe.');
          // }
          return res;
        }
        switch (res.status) {
          // case 400: throw Error('Error code 400 - Please provide appropriate search criteria. You can search by name/membership number/address.'); break;
          case 500: throw Error('Error code 500 - There is an error while processing this request. Please try again, if it persists contact visasupport@newcorp.com'); break;
          // case 504: throw Error('Error code 504 - The customer search service timed out while processing your request. Please try again, if it persists contact visasupport@newcorp.com'); break;
          default: throw Error('HTTP - ' + res.status + ' - ' + res.statusText);
        }
      })
      .then(function (response) {
        return response.blob();
      })
      .then(
        (blob) => {
          FileSaver.saveAs(blob, 'report-' + this.state.reportStartDate.format('YYYYMMDD') + '-' + this.state.reportEndDate.format('YYYYMMDD') + '.csv');
          this.setState({
            reportStartDate: null
          });
          this.setState({
            reportEndDate: null
          });
          this.setState({ displayReportsControl: false });
          this.setState({ inProgress: false });
        }

      )
      .catch((error) => {
        console.log('I am inside Error2' + error);

        this.setState({ SearchError: error.message });

        this.setState({ inProgress: false });
      });

  }

  validateCustomerDetails() {

    let fieldValidationErrors = { callWrapReasonMissing: null, callWrapReasonExtra: null, callWrapReasonDetailsMissing: null, CallWrapNotesMissing: null };

    var complaintReasons = ['Bank Incorrect Info', 'Call Transfer Issue', 'Asked for Supervisor', 'Bank Issue (not Priority Pass)', 'Entrance/Experience Issues', 'Poor Web Experience', 'Threat of Escalation'];
    let complaintFound = this.state.callWrapReason.some(r => complaintReasons.includes(r));

    if (this.state.callWrapReason.length === 0) {
      fieldValidationErrors.callWrapReasonMissing = true;
    }
    if (this.state.callWrapReason.length > 2) {
      fieldValidationErrors.callWrapReasonExtra = true;
    }
    if (this.state.callWrapReason.includes('Other') && this.state.callWrapReasonDetails == null) {
      fieldValidationErrors.callWrapReasonDetailsMissing = true;
    }
    if (complaintFound && this.state.selectedCustomerDetails.Notes == null) {
      fieldValidationErrors.CallWrapNotesMissing = true;
    }

    this.setState({ validationErrors: fieldValidationErrors });

    return (this.state.selectedCustomerDetails.FirstName
      //&& this.state.selectedCustomerDetails.MiddleName
      && this.state.selectedCustomerDetails.LastName
      && this.state.selectedCustomerDetails.AddressLine1
      && this.state.callWrapReason.length > 0
      && this.state.callWrapReason.length < 3
      && (this.state.callWrapReason.includes('Other') ? this.state.callWrapReasonDetails : true))
      && (complaintFound ? this.state.selectedCustomerDetails.Notes != null : true);
  }

  handleCustomerSave(event) {
    this.setState({ SearchError: null });
    event.preventDefault();

    if (!this.validateCustomerDetails()) {
      this.setState({ validationFailure: true });
      return;
    }

    var sessionData = JSON.parse(window.sessionStorage.getItem("oidc.user:" + config.oidc_settings.authority + ":" + config.oidc_settings.client_id));
    var accessToken;
    if (sessionData) {
      //console.log('OIDC User: '+sessionData.access_token)
      accessToken = sessionData.access_token;
    }

    this.setState({ inProgress: true });
    var bodyData = {
      "ID": this.state.selectedCustomerDetails.ID,
      "FirstName": this.state.selectedCustomerDetails.FirstName,
      "LastName": this.state.selectedCustomerDetails.LastName,
      "AddressLine1": this.state.selectedCustomerDetails.AddressLine1,
      "AddressLine2": this.state.selectedCustomerDetails.AddressLine2 ? this.state.selectedCustomerDetails.AddressLine2 : null,
      "City": this.state.selectedCustomerDetails.City ? this.state.selectedCustomerDetails.City : null,
      "State": this.state.selectedCustomerDetails.State ? this.state.selectedCustomerDetails.State : null,
      "Zip": this.state.selectedCustomerDetails.ZIP ? this.state.selectedCustomerDetails.ZIP : null,
      "CountryCode": this.state.selectedCustomerDetails.CountryCode,
      "Rush": (this.state.selectedCustomerDetails.Rush === 'true'),
      "ReasonForCall": this.state.callWrapReason[0],
      "ReasonForCallDesc": this.state.callWrapReasonDetails,
      "Notes": this.state.selectedCustomerDetails.Notes,
      "MembershipNo": this.state.selectedCustomerDetails.MembershipNo,
      "ProgramCode": this.state.selectedCustomerDetails.ProgramCode,
      "ARNNumber": this.state.selectedCustomerDetails.CCA,
      "StartTime": this.state.callStartTime,
      "EndTime": new Date().toISOString()
    }

    if (this.state.callWrapReason.length > 1) {
      bodyData.ReasonForCall2 = this.state.callWrapReason[1];
    }

    //console.log(bodyData);
    // fetch("https://0ryczxtpyh.execute-api.us-east-1.amazonaws.com/dev/cbsi/v1/customer", {  //with authentication
    fetch(config.apigateway_settings.base_server + "cbsi/v1/customer", {  //with authentication
      //fetch("https://6poopife3f.execute-api.us-east-1.amazonaws.com/test/cbsi/v1/customer", {
      //fetch("https://wf0k6bsmfh.execute-api.us-east-1.amazonaws.com/test/cbsi/v1/customer", {
      method: 'PUT',
      headers: {
        RequestTimeStamp: '2014-07-29T16:31:10.75-04:00',
        UniqueRequestId: '27bd474b-b62d-4ad3-ad9c-79800ce1e7c9',
        SessionId: '6e883d66-5ae5-48da-8b54-8bbfc4157df5',
        Sender: 'PORTAL',
        'Content-Type': 'application/json'
        , Authorization: 'Bearer ' + accessToken
      },
      body: JSON.stringify(bodyData)
    })
      .then(res => {
        if (!res) {
          throw Error('No response recieved');
        }
        if (res.ok) {
          return res;
        }
        switch (res.status) {
          case 400: throw Error('Error code 400 - Please provide all required information and try again.'); break;
          case 500: throw Error('Error code 500 - There is an error while processing this request. Please try again, if it persists contact visasupport@newcorp.com'); break;
          case 504: throw Error('Error code 504 - The call wrap service timed out while processing your request. Please try again, if it persists contact visasupport@newcorp.com'); break;
          default: throw Error('HTTP - ' + res.status + ' - ' + res.statusText);
        }
      })
      .then(function (response) {
        return response.json();
      })
      .then(
        (result) => {

          this.clearApplcationState();

          this.setState({ callStartTime: null });

          this.setState({
            customerUpdateSuccessFul: true
          });
          this.setState({ inProgress: false });
          console.log(result);
        },
        (error) => {
          console.log('I am inside Error' + error);
          this.setState({ SearchError: error.message });
          this.setState({ inProgress: false });
        }
      )
      .catch((error) => {
        console.log('I am inside Error' + error);
        this.setState({ SearchError: error.message });
        this.setState({ inProgress: false });
      });
  }

  captureSearchTerm(event) {
    this.setState({ SearchError: null });
    this.setState({ customerUpdateSuccessFul: null });
    this.setState({ searchTerm: event.target.value });
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  handleCustomerSearch(event) {
    if (event.key === 'Enter' && this.state.searchTerm.trim().length > 0) {
      let searchText = this.state.searchTerm;
      let searchCount = this.state.searchCount;
      this.clearApplcationState();
      this.setState({ searchCount: ++searchCount });
      this.setState({ searchTerm: searchText });
      this.setState({ inProgress: true });

      //alert('A name was submitted: ' + this.state.searchTerm);
      event.preventDefault();

      var sessionData = JSON.parse(window.sessionStorage.getItem("oidc.user:" + config.oidc_settings.authority + ":" + config.oidc_settings.client_id));
      var accessToken;
      if (sessionData) {
        //console.log('OIDC User: '+sessionData.access_token)
        accessToken = sessionData.access_token;
      }


      //var searchResponse = null;
      //fetch("https://0ryczxtpyh.execute-api.us-east-1.amazonaws.com/dev/cbsi/v1/customer?searchString=" + this.state.searchTerm, {  //with authentication
      fetch(config.apigateway_settings.base_server + "cbsi/v1/customer?searchString=" + this.state.searchTerm, {  //with authentication
        //fetch("https://6poopife3f.execute-api.us-east-1.amazonaws.com/test/cbsi/v1/customer?searchString=" + this.state.searchTerm, {
        // fetch("https://wf0k6bsmfh.execute-api.us-east-1.amazonaws.com/test/cbsi/v1/customer?searchString=" + this.state.searchTerm, {

        method: 'GET',
        headers: {
          RequestTimeStamp: '2014-07-29T16:31:10.75-04:00',
          UniqueRequestId: '27bd474b-b62d-4ad3-ad9c-79800ce1e7c9',
          SessionId: '6e883d66-5ae5-48da-8b54-8bbfc4157df5',
          Sender: 'PORTAL'
          , Authorization: 'Bearer ' + accessToken
        },
      })
        .then(res => {
          if (res.ok) {
            if (res.status === 204) {
              throw Error('[try searching another way] [or ask] When did you complete enrollment? It can take up to 3-4 business days before we can see your priority pass in our system, I would recommend that you give us a call back after that timeframe. It takes 6 business days from the time a replacement is processed until a DMC can be set up on the new number: (SAP, JPM, RCC only)');
            }
            return res;
          }
          switch (res.status) {
            case 400: throw Error('Error code 400 - Please provide appropriate search criteria. You can search by name/membership number/address.'); break;
            case 500: throw Error('Error code 500 - There is an error while processing this request. Please try again, if it persists contact visasupport@newcorp.com'); break;
            case 504: throw Error('Error code 504 - The customer search service timed out while processing your request. Please try again, if it persists contact visasupport@newcorp.com'); break;
            default: throw Error('HTTP - ' + res.status + ' - ' + res.statusText);
          }
        })
        .then(function (response) {
          return response.json();
        })
        .then(
          (result) => {
            //console.log(result);
            if (result.Customers && result.Customers.length > 0) {
              this.setState({
                // searchSuccess: true,
                searchResults: result.Customers
              });
              //this.setState({ searchTerm: searchText });
              this.setState({ inProgress: false });
              this.setState({ displaySearchResults: (this.state.searchResults && this.state.searchResults.length > 0) ? true : false });
            }
            else {
              this.setState({
                //  searchSuccess: false,
                searchResults: result.Customers
              });
              //this.setState({ searchTerm: searchText });
            }
          }
          // (error) => {
          //   console.log('I am inside Error1' + error);

          //   this.setState({ SearchError: error.message });
          //   this.setState({ inProgress: false });
          // }
        )
        .catch((error) => {
          console.log('I am inside Error2' + error);

          this.setState({ SearchError: error.message });

          this.setState({ inProgress: false });
        });
    }
    else {
          this.setState({ SearchError: "Please provide appropriate search criteria. You can search by name/membership number/address." });

          this.setState({ inProgress: false });
    }
  }

  handleBackToSearchResults() {
    this.setState({ SearchError: null });
    this.setState({ callWrapReason: [] });
    this.setState({ callWrapReasonDetails: null });
    this.setState({ customerUpdateSuccessFul: null });
    this.setState({ displaySearchResults: true });
    this.setState({
      selectedCustomerDetails: null
    });
    this.setState({ validationFailure: false });
  }

  handleCallResonChange(event, reason) {
    let reasons = this.state.callWrapReason;

    if (reasons.includes(reason.value)) {
      reasons.splice(reasons.indexOf(reason.value), 1);
      this.setState({ callWrapReason: reasons });
    }
    else {
      reasons.push(reason.value);
      this.setState({ callWrapReason: reasons });
    }
  }

  handleCallReasonDetailsChange(event) {
    this.setState({ callWrapReasonDetails: event.target.value });
  }

  openInNewTab(e) {
    window.open(config.faqURL, '_blank', "width=1000,height=800");
    //win.focus();
  }
  
  componentDidMount() {

  }

  clearApplcationState() {
    this.setState({ SearchError: null });
    this.setState({ searchResults: null });
    this.setState({ selectedCustomerDetails: null });
    this.setState({ displaySearchResults: false });
    this.setState({ SearchError: null });
    this.setState({ customerUpdateSuccessFul: null });
    this.setState({ callWrapReason: [] });
    this.setState({ callWrapReasonDetails: null });
    this.setState({ validationFailure: false });
    this.setState({ displayReportsControl: false });
    this.setState({
      reportStartDate: null
    });
    this.setState({
      reportEndDate: null
    });


  }

  componentWillMount() {
    document.title = 'Bluestreak - PriorityPass'
  }

  componentWillUnmount() {

  }

  handleCustomerDetailsChange(event) {

    let selectedCustomerDetails = Object.assign({}, this.state.selectedCustomerDetails);
    console.log(selectedCustomerDetails[event.target.name]);
    selectedCustomerDetails[event.target.name] = (event.target.value) ? event.target.value.trim() : event.target.value;
    this.setState({ selectedCustomerDetails });


    console.log('Input State updated');
  }

  handleDisplayResults() {
    let currentVisibility = this.state.displayReportsControl;
    this.setState({ displayReportsControl: !currentVisibility });
  }

  render() {


    const displaySearchResults = this.state.displaySearchResults;
    const isCustomerSelected = this.state.selectedCustomerDetails;
    const isError = this.state.SearchError;
    const isCustomerUpdateSuccessFul = this.state.customerUpdateSuccessFul;
    const isInProgress = this.state.inProgress;
    const closeThisWindow = !this.state.callStartTime;
    const displayReportsControl = this.state.displayReportsControl;

    let userSessionData = JSON.parse(window.sessionStorage.getItem("oidc.user:" + config.oidc_settings.authority + ":" + config.oidc_settings.client_id));
    let isReportUser = config.reportADGroups.map(g=> g.toUpperCase()).some(r => userSessionData.profile.groups.map(g=> g.toUpperCase()).includes(r));


    const welcomeScript = <Label basic color='blue' pointing='below' size='large'>Thank you for calling Card Benefit Services.  Calls may be monitored or recorded for quality and customer service purposes.  My name is [Insert Name]. How may I assist you?</Label>;
    const searchResultsGrid = displaySearchResults ?
      <SearchCustomerResults
        searchResults={this.state.searchResults}
        onCustomerClick={this.handleCustomerSelection}
      />
      : '';

    const reportsControl = <ComplaintReport
      startDate={this.state.reportStartDate}
      endDate={this.state.reportEndDate}
      //startDateSelected ={this.handleStartDateChange}
      handleStartChange={this.handleStartDateChange}
      //endDateSelected={this.props.endDateSelected}
      handleEndChange={this.handleEndDateChange}
      getweeklyReports={this.validateReportDates}
      validationErrors={this.state.validationErrors}
    />;



    const customerDetails = isCustomerSelected ?
      <CustomerDetails
        callReason={this.state.callWrapReason}
        callReasonDetails={this.state.callWrapReasonDetails}
        validationFailure={this.state.validationFailure}
        validationErrors={this.state.validationErrors}
        customer={this.state.selectedCustomerDetails}
        onCustomerSave={this.handleCustomerSave}
        onCustomerDetailsChange={this.handleCustomerDetailsChange}
        onBackToSearchResults={this.handleBackToSearchResults}
        onCallReasonChange={this.handleCallResonChange}
        onCallReasonDetailsChange={this.handleCallReasonDetailsChange}
      />
      : '';

    if (closeThisWindow) {
      return (
        <h2>Call wrap complete, please close this window/tab.</h2>
      );
    }

    return (


      <div>


        <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.12/semantic.min.css"></link>

        <br />
        <div align="center">
          {(this.state.searchCount > 0) ? '' : welcomeScript}
        </div>
        <h1>Priority Pass Portal </h1>
        <Dimmer.Dimmable as={Segment} dimmed={isInProgress}>
          {isReportUser ? <Button floated='right' onClick={this.handleDisplayResults}>Complaint Report</Button> : ''}
          <Button floated='right' onClick={this.openInNewTab}>FAQs</Button>
          <br />


          <Dimmer active={isInProgress} inverted>
            <Loader size='large'>processing</Loader>
          </Dimmer>


          {isError ?
            <Message
              error
              header='Error'
              content={this.state.SearchError}
            />
            : ''}
          {isCustomerUpdateSuccessFul ?
            <Message
              success
              header='Success'
              content='Action completed successfully.'
            /> : ''}
          <br />


          {displayReportsControl ? reportsControl : ''}
          <br />

          {!isCustomerSelected ?
            (<div>
              <h3>Customer Search </h3>
              <br />
              <Input fluid icon='search' placeholder="Membership Number / Name / Address" onChange={this.captureSearchTerm} defaultValue={this.state.searchTerm} onKeyPress={this.handleCustomerSearch} />
              <br />
              <br />
              {searchResultsGrid}
            </div>) : ''}

          {customerDetails}
        </Dimmer.Dimmable>
      </div>


    );
  }
}