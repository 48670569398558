import React from 'react';
import { Redirect } from 'react-router-dom'
import { getStage } from "../../misc";
//import { Analytics } from 'aws-amplify';


const stage = getStage()
var config = require('../../config/config_' + stage)

export default function requireAuth(WrappedComponent) {

    return class AuthenticationCheck extends React.Component {

        render() {

            var sessionData = JSON.parse(window.sessionStorage.getItem("oidc.user:" + config.oidc_settings.authority + ":" + config.oidc_settings.client_id));
            if (sessionData) {

                var curTime = Math.round(+new Date() / 1000);
                var expTime = sessionData.expires_at;
                var expDate = new Date(expTime * 1000);
                expDate.setHours(expDate.getHours() - 1);
                expTime = Math.round(expDate / 1000);

                if (curTime > expTime) {
                    return <Redirect to="/" />
                }

                if (config.adGroups.map(g=> g.toUpperCase()).some(r => sessionData.profile.groups.map(g=> g.toUpperCase()).includes(r))) {
                    // Analytics.record({
                    //     name: 'ADGroup validated', 
                    //     attributes: { adGroups: config.adGroups }
                    // });
                    return <WrappedComponent {...this.props} />
                }
                return <Redirect to="/unauthorized" />
            }
            return <Redirect to="/" />
        }
    }
}
