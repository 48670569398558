import React from 'react';
import oidcUserManager from '../../oidcManager';

export default class AuthenticateUser extends React.Component {
  constructor(props) {
    super(props);

    oidcUserManager.signinRedirect({ state: 'some data' }).then(function (data) {
      console.log("signinRedirect done");
    }).catch(function (err) {
      console.log(err);
    });

  }

  render() {
    return '';
  }
}
