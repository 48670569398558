import React from 'react';
import CustomerResultsRow from '../../components/CustomerResultsRow'
import { Table } from 'semantic-ui-react'


export default class SearchCustomerResults extends React.Component {
  // constructor(props) {
  //   super(props);
  // }



  render() {
    //console.log(this);

    var resultRows = [];

    this.props.searchResults.forEach((result) => {
      resultRows.push(<CustomerResultsRow onCustomerClick={this.props.onCustomerClick} CustomerId={result.ID} FirstName={result.FirstName} LastName={result.LastName}
        FullAddress={
          ((result.AddressLine1) ? result.AddressLine1 : '') + ' '
          + ((result.AddressLine2) ? result.AddressLine2 : '') + ' '
          + ((result.City) ? result.City : '') + ' '
          + ((result.State) ? result.State : '') + ' '
          + ((result.CountryCode) ? result.CountryCode : '') + ' '
          + ((result.ZIP) ? result.ZIP : '')}
        MembershipNo={result.MembershipNo} Role={result.Role} ExpDate={result.ExpDate} ProgramCode={result.ProgramCode} Inactive={(result.Inactive === 'true') ? 'YES' : 'NO'} EnteredDate={result.EnteredDate} />);
    });

    return (

      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Program</Table.HeaderCell>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell>Membership Number</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Date of Expiration</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Entered Date</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {resultRows}
        </Table.Body>
      </Table>
    );
  }
}